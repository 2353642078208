import type { LogoSvgProps } from './Logo';

export const LogoSvg_Secondary = (props: LogoSvgProps) => (
  <svg viewBox="0 0 1049 391" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path
        d="M299.8 113.7H307.3C307.7 113.7 308 113.5 308.2 113.2L336 74.2999C336.2 73.9999 336.6 73.7999 336.9 73.7999H380.4C381.4 73.7999 381.9 74.9 381.3 75.7L338.8 130C338.5 130.4 338.5 131 338.8 131.4L381.7 192.4C382.2 193.2 381.7 194.2 380.8 194.2H337.3C336.9 194.2 336.5 194 336.3 193.7L307.3 148.3C307.1 148 306.7 147.8 306.3 147.8H299.9C299.3 147.8 298.7 148.3 298.7 148.9V193.1C298.7 193.7 298.2 194.3 297.6 194.3H261.5C260.9 194.3 260.3 193.8 260.3 193.1V19.8999C260.3 19.2999 260.8 18.7999 261.5 18.7999H297.6C298.2 18.7999 298.7 19.2999 298.7 19.8999V112.6C298.7 113.2 299.2 113.8 299.9 113.8"
        fill="#3DD993"
      />
      <path
        d="M414.7 134C414.7 150.2 425.7 161.6 440.7 161.6C455.7 161.6 466.8 150.2 466.8 134C466.8 117.8 455.8 106.4 440.7 106.4C425.6 106.4 414.7 117.8 414.7 134ZM506.1 134C506.1 170.4 478.4 197.4 440.7 197.4C403 197.4 375.4 170.4 375.4 134C375.4 97.6 403.1 70.6 440.7 70.6C478.3 70.6 506.1 97.6 506.1 134Z"
        fill="#3DD993"
      />
      <path
        d="M557.8 194.2H521.701C521.101 194.2 520.5 193.7 520.5 193V19.8C520.5 19.2 521.001 18.6 521.701 18.6H557.8C558.4 18.6 559 19.1 559 19.8V193C559 193.6 558.5 194.2 557.8 194.2Z"
        fill="#3DD993"
      />
      <path
        d="M824.3 121.6H865.1C865.9 121.6 866.4 120.9 866.2 120.1C862.9 109 855.6 103.2 844.9 103.2C834.2 103.2 826.4 109.2 823.2 120.2C823 120.9 823.6 121.6 824.3 121.6ZM905 134C905 137.5 904.8 142.1 904.4 144.9C904.4 145.5 903.8 145.9 903.3 145.9H824.2C823.4 145.9 822.9 146.7 823.1 147.4C826.6 159.5 835.7 165.2 848.5 165.2C861.3 165.2 866.5 161.8 874 155.3C874.4 154.9 875.1 154.9 875.5 155.3L898.6 176.8C899.1 177.2 899.1 178 898.6 178.5C886.1 190.5 868.2 197.4 846.5 197.4C808.7 197.4 783.1 172.2 783.1 134.7C783.1 97.2 808.2 70.5 844.8 70.5C881.4 70.5 904.9 96.3 905 133.9"
        fill="#3DD993"
      />
      <path
        d="M957.6 134C957.6 150.2 968.6 161.6 983.6 161.6C998.6 161.6 1009.7 150.2 1009.7 134C1009.7 117.8 998.7 106.4 983.6 106.4C968.5 106.4 957.6 117.8 957.6 134ZM1049 134C1049 170.4 1021.3 197.4 983.6 197.4C945.9 197.4 918.3 170.4 918.3 134C918.3 97.6 946 70.6 983.6 70.6C1021.2 70.6 1049 97.6 1049 134Z"
        fill="#3DD993"
      />
      <path
        d="M604.5 103.2H607.6C608.1 103.2 608.6 102.8 608.7 102.3C609.8 97 616.6 70.6 648.8 70.6C681 70.6 677.7 82 684.4 102.4C684.6 102.9 685 103.2 685.5 103.2C686 103.2 686.4 102.9 686.6 102.4C692 85.8 705.1 70.6 723.7 70.6C751.2 70.6 768.6 87.5 768.6 115.2V193.1C768.6 193.7 768.1 194.3 767.4 194.3H731.3C730.7 194.3 730.1 193.8 730.1 193.1V128.7C730.1 114.5 723 106.5 711 106.5C699 106.5 691.5 113.7 691.5 129.3V193.1C691.5 193.7 691 194.3 690.3 194.3H654.2C653.6 194.3 653 193.8 653 193.1V128.9C653 114.7 645.9 106.7 634 106.7C622.1 106.7 614.5 113.9 614.5 129.5V193.1C614.5 193.7 614 194.3 613.4 194.3H577.3C576.7 194.3 576.1 193.8 576.1 193.1V75.1C576.1 74.5 576.6 73.9 577.2 73.9H594.7C595.2 73.9 595.7 74.2 595.8 74.7L603.1 102.3C603.2 102.8 603.7 103.2 604.2 103.2"
        fill="#3DD993"
      />
      <path
        d="M193.6 106.5C204.8 117.7 211.8 133.2 211.8 150.4C211.8 184.7 184 212.4 149.8 212.4C115.6 212.4 117.2 205.5 105.9 194.2L18.2002 106.5L105.9 18.8C117.1 7.59996 132.6 0.599976 149.8 0.599976C184.1 0.599976 211.8 28.4 211.8 62.6C211.8 96.8 204.9 95.2 193.6 106.5Z"
        fill="#0082B4"
      />
      <path
        d="M124.1 150.3C124.1 184.6 96.3001 212.3 62.1001 212.3C27.9001 212.3 0.100098 184.5 0.100098 150.3V62.6C0.100098 28.3 27.9001 0.599976 62.1001 0.599976C96.3001 0.599976 124.1 28.4 124.1 62.6V150.3Z"
        fill="#3DD993"
      />
      <path
        d="M105.966 18.6884L18.2144 106.44L105.966 194.192L193.718 106.44L105.966 18.6884Z"
        fill="#251EB4"
      />
      <path
        d="M539.5 240.6C545 240.6 547.8 242.7 547.6 246C547.6 249.5 544.7 250.3 541.7 250.1C538.6 249.9 536.3 248.9 534.2 248.9C528.6 248.9 526.4 257.9 525.7 262.2V277.8C525.7 283.8 530.2 282.2 530.2 285C530.2 287.8 529.2 287.1 526.4 287.3C525.1 287.5 523.6 287.6 521.9 287.6C520.2 287.6 518.6 287.6 517.2 287.3C514.5 287.1 513.3 286.6 513.3 285C513.3 282.2 517.9 283.8 517.9 277.8V250.2C517.9 246.7 513.3 246.5 513.3 243.4C513.3 240.3 515.1 241.1 518 241.1H520.3C522.3 241.1 524.2 242.1 524.9 244.7C525.1 246.1 525.3 250.9 525.4 252.2C525.4 252.7 525.7 252.8 525.9 252.2C528.1 246.1 532.2 240.6 539.6 240.6H539.5Z"
        fill="#0082B4"
      />
      <path
        d="M573.4 282.3C578.7 282.3 582.6 279.1 586.2 274.7C586.9 273.8 587.8 273.3 588.6 273.3C589.4 273.3 590.1 274.1 589.1 276.3C585.6 284.9 579 287.9 571 288.1C560.3 288.1 552.2 282 550.1 269.3C547.7 255.3 554.6 241.6 568.9 240.5C577.9 239.7 584.3 243.5 587.3 249.4C590.7 255.7 587.3 259.8 580.4 263L561.2 272.3C560.6 272.6 560.4 273.1 560.7 273.7C563.2 279.8 567.7 282.2 573.4 282.2V282.3ZM574.2 260.7C579.1 258.4 582.6 256.2 580.1 251.8C578.4 248.4 574.5 245.1 569.6 245.1C564.7 245.1 567.8 245.3 566.9 245.5C557.7 247.5 556.2 258 558.1 266.9C558.1 267.6 558.7 267.9 559.4 267.6L574.2 260.8V260.7Z"
        fill="#0082B4"
      />
      <path
        d="M623.2 240.6C632.9 240.6 638.5 246.3 638.5 256.1V277.7C638.5 283.7 642.7 282.2 642.7 285C642.7 287.8 641.6 287.3 639.1 287.4C636.3 287.8 632.6 287.7 630 287.4C627.3 287.2 626.1 286.7 626.1 285C626.1 282.2 630.7 283.7 630.7 277.7V258.3C630.7 250.6 627.2 246.4 620.9 246.4C614.6 246.4 607.7 259.1 607.2 266.1V277.7C607.2 283.7 611.4 282.2 611.4 285C611.4 287.8 610.3 287.3 607.8 287.4C605 287.8 601.3 287.7 598.7 287.4C596 287.2 594.8 286.7 594.8 285.1C594.8 282.3 599.4 283.9 599.4 277.9V250.3C599.4 246.7 594.8 246.6 594.8 243.5C594.8 240.4 596.6 241.2 599.5 241.2H601.8C604 241.2 606.4 242.4 606.9 245.4C606.9 246.5 606.9 251 607.1 252.5C607.1 253.2 607.5 253.3 607.7 252.5C610.7 245.8 616.2 240.5 623.4 240.5L623.2 240.6Z"
        fill="#0082B4"
      />
      <path
        d="M647.4 246.8C644.2 246.8 644 241.2 647.4 241.2C650.8 241.2 650.9 241.5 652.8 241.6C653.3 241.6 653.7 241.3 653.7 240.8V231C653.7 228.5 657.2 226.5 659.6 225.8C661.1 225.3 661.5 226.5 661.5 228V241.1C661.5 241.7 661.9 242 662.4 241.9C665.8 241.7 668.9 241.3 673 241.3C677.1 241.3 676.4 246.9 673 246.9C669.6 246.9 665.9 246.4 662.4 246.2C661.9 246.2 661.5 246.5 661.5 247V273.9C661.5 280.3 663 282.2 665 282.2C667 282.2 672 278.3 674.4 278.3C676.8 278.3 675 278.3 675.3 278.6C678.1 280.7 673.2 288.3 664.3 288.3C655.4 288.3 653.6 286.8 653.6 274.8V247.5C653.6 246.9 653.2 246.6 652.7 246.7C651.6 246.7 650.3 246.9 647.3 247L647.4 246.8Z"
        fill="#0082B4"
      />
      <path
        d="M685.3 280.3C685.8 276.9 688.6 275.2 691 275.2C693.4 275.2 696.2 276.7 694.6 281.1L689.4 294.4C688.8 295.9 687.3 296.8 685.7 296.8C684.1 296.8 683.2 295.6 683.4 294L685.3 280.2V280.3Z"
        fill="#0082B4"
      />
      <path
        d="M743.9 240.6C755.9 240.6 765.1 247.7 765.1 264.3C765.1 280.9 755.9 288.1 743.9 288.1C731.9 288.1 722.6 281 722.6 264.3C722.6 247.6 731.9 240.6 743.9 240.6ZM742.5 244.8C735 245.4 730.3 251.3 731.2 265.1C732.1 279 737.8 284.4 745.1 284C752.7 283.4 757.4 277.4 756.5 263.6C755.5 249.9 749.9 244.4 742.5 244.9V244.8Z"
        fill="#0082B4"
      />
      <path
        d="M793.6 281.8C792.3 285.6 791.1 287.5 788.6 287.5H787.1C784.6 287.5 782.9 285.5 781.7 282L770.2 250C768.7 245.7 764.6 245.9 764.6 243.3C764.6 240.7 766 241.1 768.9 241.1H771.7C774.3 241.1 776.4 243.1 777.6 246.7L788.9 278.5C789.1 279.3 789.5 279.3 789.8 278.5L798.4 253.7C798.7 252.9 798.7 251.8 798.4 251L798 249.9C796.5 245.6 792.5 245.8 792.5 243.2C792.5 240.6 793.9 241 796.8 241H799.6C802.2 241 804.3 242.9 805.4 246.6L816.4 277.9C816.7 278.7 817 278.7 817.3 277.9L826.2 251.5C828.4 244.7 823.2 244.8 823.9 242.4C824.2 241.3 827.4 240.8 830.5 240.8C833.6 240.8 836.3 241.4 836.5 242.6C836.7 245.1 833 244.9 830.8 251.8L821.1 281.5C819.9 285.3 818.9 287.2 816.1 287.2H814.7C812.2 287.2 810.4 285.2 809.2 281.7L801.6 260.6C801.3 259.8 800.9 259.8 800.6 260.6L793.4 281.5L793.6 281.8Z"
        fill="#0082B4"
      />
      <path
        d="M867.7 240.6C877.4 240.6 883 246.3 883 256.1V277.7C883 283.7 887.2 282.2 887.2 285C887.2 287.8 886.1 287.3 883.6 287.4C880.8 287.8 877.1 287.7 874.5 287.4C871.8 287.2 870.6 286.7 870.6 285C870.6 282.2 875.2 283.7 875.2 277.7V258.3C875.2 250.6 871.7 246.4 865.4 246.4C859.1 246.4 852.2 259.1 851.7 266.1V277.7C851.7 283.7 855.9 282.2 855.9 285C855.9 287.8 854.8 287.3 852.3 287.4C849.5 287.8 845.8 287.7 843.2 287.4C840.5 287.2 839.3 286.7 839.3 285.1C839.3 282.3 843.9 283.9 843.9 277.9V250.3C843.9 246.7 839.3 246.6 839.3 243.5C839.3 240.4 841.1 241.2 844 241.2H846.3C848.5 241.2 850.9 242.4 851.4 245.4C851.4 246.5 851.4 251 851.6 252.5C851.6 253.2 852 253.3 852.2 252.5C855.2 245.8 860.7 240.5 867.9 240.5L867.7 240.6Z"
        fill="#0082B4"
      />
      <path
        d="M897.4 280.3C897.9 276.9 900.7 275.2 903.1 275.2C905.5 275.2 908.3 276.7 906.6 281.1L901.4 294.4C900.8 295.9 899.3 296.8 897.7 296.8C896.1 296.8 895.2 295.6 895.4 294L897.3 280.2L897.4 280.3Z"
        fill="#0082B4"
      />
      <path
        d="M568.9 324.9C577.8 324.9 583 330.6 583 340.4V361.9C583 368 587.1 366.4 587.1 369.2C587.1 372 586.1 371.5 583.6 371.7C580.8 372.1 577.1 372 574.5 371.7C571.8 371.4 570.6 371 570.6 369.2C570.6 366.4 575.1 368 575.1 361.9V342.6C575.1 334.9 572.1 330.7 566.5 330.7C560.9 330.7 555.1 341.8 554.3 349.2V362C554.3 368.1 558.5 366.5 558.5 369.3C558.5 372.1 557.4 371.6 555 371.8C552.1 372.2 548.4 372.1 545.8 371.8C543.1 371.5 542 371.1 542 369.5C542 366.7 546.6 368.1 546.6 362V342.7C546.6 335 543.5 330.8 538 330.8C532.5 330.8 526.6 341.9 525.9 349.3V362.1C525.9 368.1 530.1 366.6 530.1 369.4C530.1 372.2 529 371.7 526.5 371.9C523.7 372.2 520 372.2 517.4 371.9C514.7 371.6 513.5 371.2 513.5 369.5C513.5 366.8 518.1 368.3 518.1 362.3V334.8C518.1 331.2 513.5 331.1 513.5 328C513.5 324.9 515.3 325.7 518.2 325.7H520.5C522.7 325.7 524.9 326.9 525.5 329.8C525.5 330.6 525.5 335.5 525.5 336.4C525.7 337.2 525.9 337.2 526.2 336.4C529.1 330.1 534 325.1 540.4 325.1C546.8 325.1 552.7 329.3 553.9 336.7C554.1 337.5 554.4 337.5 554.7 336.7C557.5 330.2 562.4 325 569 325L568.9 324.9Z"
        fill="#0082B4"
      />
      <path
        d="M621 365.2C618.1 369.5 613 372.4 606.9 372.4C600.8 372.4 592.3 369.3 592.3 359.8C592.3 350.3 610.6 344.9 620.2 343.6C620.8 343.6 621.2 343.1 621.2 342.5V340.4C621.2 333.4 618.4 328.7 612.7 328.7C607 328.7 604.9 333 602.3 335.7C600.8 337.3 599.1 338 597.6 338C596.1 338 594.2 336.8 594.2 334.6C594.2 329.5 603.7 324.8 612.8 324.8C621.9 324.8 629 329.6 629 340.7V359.5C629 367.6 633.6 365.7 634.7 367.4C635.8 369 632.8 372 628.7 372C624.6 372 623.3 370.9 622.1 365.3C621.9 364.4 621.6 364.4 621 365.1V365.2ZM621.2 348.5C621.2 347.8 620.8 347.5 620.2 347.6C613.8 348.4 601.1 351 600.9 359.1C600.7 364.4 605.1 367.8 609.8 367.6C615.5 367.6 620.2 362.8 621.2 357.8V348.5Z"
        fill="#0082B4"
      />
      <path
        d="M666.2 324.9C675.9 324.9 681.5 330.6 681.5 340.4V362C681.5 368 685.7 366.5 685.7 369.3C685.7 372.1 684.6 371.6 682.1 371.7C679.3 372.1 675.6 372 673 371.7C670.3 371.5 669.1 371 669.1 369.3C669.1 366.5 673.7 368 673.7 362V342.6C673.7 334.9 670.2 330.7 663.9 330.7C657.6 330.7 650.7 343.4 650.2 350.4V362C650.2 368 654.4 366.5 654.4 369.3C654.4 372.1 653.3 371.6 650.8 371.7C648 372.1 644.3 372 641.7 371.7C639 371.5 637.8 371 637.8 369.4C637.8 366.6 642.4 368.2 642.4 362.2V334.6C642.4 331 637.8 330.9 637.8 327.8C637.8 324.7 639.6 325.5 642.5 325.5H644.8C647 325.5 649.3 326.7 649.9 329.7C649.9 330.8 649.9 335.3 650.1 336.8C650.1 337.5 650.5 337.6 650.7 336.8C653.7 330.1 659.2 324.8 666.4 324.8L666.2 324.9Z"
        fill="#0082B4"
      />
      <path
        d="M719.6 365.2C716.7 369.5 711.6 372.4 705.5 372.4C699.4 372.4 690.9 369.3 690.9 359.8C690.9 350.3 709.2 344.9 718.8 343.6C719.4 343.6 719.8 343.1 719.8 342.5V340.4C719.8 333.4 717 328.7 711.3 328.7C705.6 328.7 703.5 333 700.9 335.7C699.4 337.3 697.7 338 696.2 338C694.7 338 692.8 336.8 692.8 334.6C692.8 329.5 702.3 324.8 711.4 324.8C720.5 324.8 727.6 329.6 727.6 340.7V359.5C727.6 367.6 732.2 365.7 733.3 367.4C734.4 369 731.4 372 727.3 372C723.2 372 721.9 370.9 720.7 365.3C720.5 364.4 720.2 364.4 719.6 365.1V365.2ZM719.8 348.5C719.8 347.8 719.4 347.5 718.8 347.6C712.4 348.4 699.7 351 699.5 359.1C699.3 364.4 703.7 367.8 708.4 367.6C714.1 367.6 718.8 362.8 719.8 357.8V348.5Z"
        fill="#0082B4"
      />
      <path
        d="M757.9 324.9C763.1 324.9 767.8 327.2 771 331.4C771 331 771 329.3 771 328.9C771.7 326.5 773.5 325.5 775.5 325.5H777.1C779 325.5 780.1 326.3 780.1 327.8C780.1 330.4 777.8 331.1 777.8 334.6V372.6C777.8 386.1 768.3 390.7 758.9 390.7C749.5 390.7 740.8 387.6 739.6 381.6C739 379.2 740.3 377.7 742.3 377.7C744.3 377.7 744.9 378.4 746.2 379.6C749.1 382.4 751.5 386.4 758.8 386.4C766.1 386.4 770 381.7 770 373.5V365.8C767.4 369.4 763.6 372.2 757.8 372.2C745.5 372.2 736.1 364.9 736.1 348.4C736.1 331.9 745.5 325 757.7 325L757.9 324.9ZM744.9 349.1C745.5 363 751.2 368 758.6 368C765.2 367.8 768.6 364.1 770.2 359.7V337.2C766.9 331 762.3 328.9 757.2 329.1C749.7 329.5 744.4 335.3 744.9 349.2V349.1Z"
        fill="#0082B4"
      />
      <path
        d="M807.2 366.6C812.5 366.6 816.4 363.4 820 359C820.7 358.1 821.6 357.6 822.4 357.6C823.2 357.6 823.9 358.4 822.9 360.6C819.4 369.2 812.8 372.2 804.8 372.4C794.1 372.4 786 366.3 783.9 353.6C781.5 339.6 788.4 325.9 802.7 324.8C811.7 324 818.1 327.8 821.1 333.7C824.5 340 821.1 344.1 814.2 347.3L795 356.6C794.4 356.9 794.2 357.4 794.5 358C797 364.1 801.5 366.5 807.2 366.5V366.6ZM807.9 344.9C812.8 342.6 816.3 340.4 813.8 336C812.1 332.6 808.2 329.3 803.3 329.3C798.4 329.3 801.5 329.5 800.6 329.7C791.4 331.7 789.9 342.2 791.8 351.1C791.8 351.8 792.4 352.1 793.1 351.8L807.9 345V344.9Z"
        fill="#0082B4"
      />
      <path
        d="M831.3 364.6C831.8 361.2 834.6 359.5 837 359.5C839.4 359.5 842.2 361 840.5 365.4L835.3 378.7C834.7 380.2 833.2 381.1 831.6 381.1C830 381.1 829.1 379.9 829.3 378.3L831.2 364.5L831.3 364.6Z"
        fill="#0082B4"
      />
      <path
        d="M877.6 362.1C877.6 368.1 882.1 366.5 882.1 369.2C882.1 371.9 881.1 371.4 878.3 371.6C875.9 371.9 871.7 371.9 869.1 371.6C866.4 371.3 865.2 370.9 865.2 369.2C865.2 366.5 869.8 368.1 869.8 362.1V310.4C869.8 306.9 865.2 306.8 865.2 303.7C865.2 300.6 866.9 301.4 869.6 301.4H872.2C875.5 301.4 877.7 303.1 877.7 306.9V362L877.6 362.1Z"
        fill="#0082B4"
      />
      <path
        d="M907.2 324.9C919.2 324.9 928.4 332 928.4 348.6C928.4 365.2 919.2 372.4 907.2 372.4C895.2 372.4 885.9 365.3 885.9 348.6C885.9 331.9 895.2 324.9 907.2 324.9ZM905.8 329.1C898.3 329.7 893.6 335.6 894.5 349.4C895.4 363.3 901.1 368.7 908.4 368.3C916 367.7 920.7 361.7 919.8 347.9C918.8 334.2 913.2 328.7 905.8 329.2V329.1Z"
        fill="#0082B4"
      />
      <path
        d="M963.7 336C966.2 329.3 961.1 329.3 961.9 326.9C962.2 325.8 965.4 325.3 968.5 325.3C971.5 325.3 974.4 325.9 974.5 327.1C974.8 329.6 971.4 329.5 968.8 336.3L957.1 366C955.6 369.7 955.3 371.7 952.6 371.7H950.6C948.1 371.7 946.6 369.6 945.2 366.2L932.1 334.2C930.4 329.9 926.6 330.1 926.6 327.5C926.6 324.9 928 325.3 930.9 325.3H933.9C936.5 325.3 938.4 327.4 939.8 330.9L952.5 361.9C952.9 362.7 953.3 362.7 953.6 361.9L963.9 335.8L963.7 336Z"
        fill="#0082B4"
      />
      <path
        d="M995.6 366.6C1000.9 366.6 1004.8 363.4 1008.4 359C1009.1 358.1 1010 357.6 1010.8 357.6C1011.6 357.6 1012.3 358.4 1011.3 360.6C1007.8 369.2 1001.2 372.2 993.2 372.4C982.5 372.4 974.4 366.3 972.3 353.6C969.9 339.6 976.8 325.9 991.1 324.8C1000.1 324 1006.5 327.8 1009.5 333.7C1012.9 340 1009.5 344.1 1002.6 347.3L983.4 356.6C982.8 356.9 982.6 357.4 982.9 358C985.4 364.1 989.9 366.5 995.6 366.5V366.6ZM996.3 344.9C1001.2 342.6 1004.7 340.4 1002.2 336C1000.5 332.6 996.7 329.3 991.7 329.3C986.7 329.3 989.9 329.5 989 329.7C979.8 331.7 978.3 342.2 980.2 351.1C980.2 351.8 980.8 352.1 981.5 351.8L996.3 345V344.9Z"
        fill="#0082B4"
      />
      <path
        d="M1023.8 361.4C1027.3 361.4 1029.8 363.8 1029.8 367.2C1029.8 370.6 1027.3 373.1 1023.8 373.1C1020.3 373.1 1017.7 370.7 1017.7 367.2C1017.7 363.7 1020.2 361.4 1023.8 361.4Z"
        fill="#0082B4"
      />
    </g>
  </svg>
);
